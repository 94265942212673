import { css } from "@emotion/react";

import { PrimaryCTAButton } from "@mytutor/shared-react-web-components/lib/PrimaryCTA";
import { breakpoints } from "@mytutor/shared-react-web-components/lib/theme";

const CTABannerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 38px 16px;
  background-image: url(landing/brand-shape3.svg), url(landing/brand-shape4.svg);
  background-position: top -200px left -725px, bottom 25px right -800px;
  background-size: 1000px, 1300px;
  background-repeat: no-repeat;

  @media (max-width: ${breakpoints.ultraLargeDesktop}px) {
    background-size: 1000px, 1300px;
    background-position: top -200px left -725px, bottom 25px right -925px;
  }

  @media (max-width: ${breakpoints.largeDesktop}px) {
    background-size: 750px, 500px;
    background-position: top -100px left -550px, bottom -50px right -250px;
  }

  @media (max-width: ${breakpoints.desktop}px) {
    background-size: 750px, 500px;
    background-position: top -60px left -600px, bottom -50px right -325px;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    background-size: 750px, 400px;
    background-position: top -60px left -625px, bottom -25px right -250px;
  }

  @media (max-width: ${breakpoints.phone}px) {
    background-size: 750px, 300px;
    background-position: top -50px left -680px, bottom 0px right -220px;
  }

  @media (max-width: ${breakpoints.smallPhone}px) {
    background-size: 750px, 300px;
    background-position: top -50px left -700px, bottom 0px right -240px;
  }

  h2 {
    max-width: 680px;
    font-size: 36px;
    font-weight: 500;
    line-height: 40px;
    text-align: center;
    margin: 0 0 40px;

    @media (max-width: ${breakpoints.tablet}px) {
      max-width: 430px;
      font-size: 21px;
      line-height: 24px;
    }

    @media (max-width: ${breakpoints.phone}px) {
      max-width: 278px;
    }
  }

  a {
    width: 142px;
  }
`;

const CTABanner = () => (
  <div css={CTABannerStyle} className="actionbanner">
    <h2>
      Book a free meeting with a tutor today and find out how they can help your
      child
    </h2>
    <a href={`${process.env.NEXT_PUBLIC_ZEUS_URL}/view-tutors/`}>
      <PrimaryCTAButton>Find a tutor</PrimaryCTAButton>
    </a>
  </div>
);

export default CTABanner;
